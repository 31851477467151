import React, { useState } from 'react'
import cx from 'classnames'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { INotification, NOTIFICATION } from 'store/store.types'
import Timezone from 'components/timezone'
import { INTEGRATED_DOCS_TAB, RUNBOOKS_TAB } from 'store/reducers/runbook.reducers'
import NCButton from 'components/nCall/NCButton'
import { addNotes } from 'services/incidents.service'
import { notification as _notification } from 'components/ui/toast/toast'
import { useAuth } from 'common/hooks/use-simple-auth'

const CustomLink = styled(Link)`
  display: contents;

  &:hover {
    background: none !important;
  }
`

const NTag = styled.span`
    width: 60px;
    height: 14px;
    font-size: 10px;
    font-weight: bold;
    text-align: center;
    color: #ffffff;
    background: #22c7e3;
    padding: 4px 7px;
    margin-left: 10px;
    border-radius: 4px;
`

const AcknowledgeButton = styled(NCButton)`
  height: 30px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const NotificationTitle = styled.strong`
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    -webkit-letter-spacing: normal;
    -moz-letter-spacing: normal;
    -ms-letter-spacing: normal;
    letter-spacing: normal;
    color: #1c2b39;
  &:hover {
    text-decoration: underline;
  }
`
const NDescription = styled.span`
  height: 30px;
  font-size: 12px;  
  line-height: 1.3;
  color: #1c2b39;
  margin-top: 5px;
`
const NTime = styled.span`
  color: #927b7b;
  font-weight: bold;
  margin-top:5px;
`
const ClientName = styled.p`
  margin: 0;
  font-weight: 700;
  color: forestgreen;
`

interface IINotification {
  notification: INotification
  isOpen: boolean
  isActionPerformed: boolean
  onRemove: (id: string) => void
  setIsActionPerformed: (isPerformed: boolean) => void
  toggleOpen: () => void
  number: number
}

const Notificaiton: React.FC<IINotification> = (props): React.ReactElement => {

  const { notification, toggleOpen, setIsActionPerformed, onRemove, number } = props
  const { user: { email } } = useAuth()
  const [loading, setLoading] = useState(false)

  const acknowledgeIncident = (incident_number: string) => {
    // call API for acknowledge
    const payload: any = [
      {
        status: 'acknowledged',
        userlogged: email,
        'incident-no': incident_number,
        note: ''
      }
    ]
    setLoading(true)
    setIsActionPerformed(true)
    addNotes(payload).then((res) => {
      const { error_code, message } = res.data
      if (error_code === 200) {
        // empty the selectedIncidents
        _notification.success({
          type: 'success',
          message: `${payload.length} Incident Acknowledged.`,
        })
        onRemove(notification.notification_id)
      } else {
        _notification.error({
          message
        })
      }
    }).catch((err) => {
      // show the error notification
      // tslint:disable-next-line:no-console
      console.log('Error while aknowledge incidents.', err)
    }).finally(() => {
      setLoading(false)
      // if (isOpen) {
      //   toggleOpen()
      // }
    })
  }

  const getTitle = () => {
    if (
      notification.type === NOTIFICATION.NEW_INCIDENT ||
      notification.type === NOTIFICATION.INCIDENT_ASSIGNED
    ) {
      return (
        <span>
          <span style={{ color: '#0071ea' }}>#{notification.type_id}</span> {notification.title}
        </span>
      )
    }
    return notification.title
  }

  // const getBackgroundColor = () => {
  //   switch (notification.type) {
  //     case NOTIFICATION.NEW_INCIDENT:
  //       return '#0071ea'
  //     case NOTIFICATION.INCIDENT_ASSIGNED:
  //       return '#0071ea'
  //     case NOTIFICATION.RUNBOOK_ADDED:
  //       return '#FDBD61'
  //     case NOTIFICATION.RUNBOOK_CHANGED:
  //       return '#FDBD61'
  //     case NOTIFICATION.DOCUMENT_ADDED:
  //       return '#29BEB9;'
  //     case NOTIFICATION.DOCUMENT_CHANGED:
  //       return '#29BEB9;'
  //     case NOTIFICATION.ALL_TRIGGERED_INCIDENTS:
  //       return '#0071ea'
  //     default:
  //       return '#0071ea'
  //   }
  // }

  // const getBadgeText = () => {
  //   switch (notification.type) {
  //     case NOTIFICATION.NEW_INCIDENT:
  //       return 'New incident'
  //     case NOTIFICATION.INCIDENT_ASSIGNED:
  //       return 'Incident assigned'
  //     case NOTIFICATION.RUNBOOK_ADDED:
  //       return 'Runbook added'
  //     case NOTIFICATION.RUNBOOK_CHANGED:
  //       return 'Runbook updated'
  //     case NOTIFICATION.DOCUMENT_ADDED:
  //       return 'Document added'
  //     case NOTIFICATION.DOCUMENT_CHANGED:
  //       return 'Document changed'
  //     case NOTIFICATION.ALL_TRIGGERED_INCIDENTS:
  //       return 'All triggered incidents'
  //     default:
  //       return 'Unknown'
  //   }
  // }

  const getLinkToRedirect = () => {
    switch (notification.type) {
      case NOTIFICATION.NEW_INCIDENT:
        return `/incidents/${notification.type_id}`
      case NOTIFICATION.INCIDENT_ASSIGNED:
        return `/incidents/${notification.type_id}`
      case NOTIFICATION.DOCUMENT_CHANGED:
        return `/nc-runbooks/${INTEGRATED_DOCS_TAB}/${notification.client_id}/view/${notification.type_id}`
      case NOTIFICATION.DOCUMENT_ADDED:
        return `/nc-runbooks/${INTEGRATED_DOCS_TAB}/${notification.client_id}/view/${notification.type_id}`
      case NOTIFICATION.RUNBOOK_ADDED:
        return `/nc-runbooks/${RUNBOOKS_TAB}/view/${notification.type_id}`
      case NOTIFICATION.RUNBOOK_CHANGED:
        return `/nc-runbooks/${RUNBOOKS_TAB}/view/${notification.type_id}`
      default:
        return '/incidents'
    }
  }

  return (
    <div
      className={cx('nc-notification', {
        even: number % 2 === 0
      })}
    >
      <div>
        <CustomLink to={getLinkToRedirect()}>
          <NotificationTitle onClick={toggleOpen}>
            {getTitle()}
          </NotificationTitle>
        </CustomLink><br />
        <NDescription>{notification.notification_description}</NDescription><NTag>Triggered</NTag><br />
        <ClientName>{notification.client_name}</ClientName>
        <NTime>
          <strong>
            <Timezone date={notification.notified_at} fromNow={true} />
          </strong>
        </NTime>
      </div>
      <div>
        <AcknowledgeButton
          loading={loading}
          color='primary'
          onClick={() => acknowledgeIncident(notification.type_id)}
        >
          Acknowledge
        </AcknowledgeButton>
      </div>
    </div>
  )
}

export default Notificaiton