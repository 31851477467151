import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { fetchIntegrationLists } from 'services/integrations.service'
import { IntegratedDataI } from '../store.types'
import { joinArrayTostring } from 'common/utils/helperFunction'

export interface ISlack {
  workspace: string
  channel_name: string
  created_by: string
  status: string
}

export type AlertMsgType = 'success' | 'error' | null

export const INTERGRATION_TABLE_COLUMNS = [
  {title: 'Client', key: 'service_name', dataIndex: 'service_name', isSorting: true},
  {title: 'Integration Type', key: 'integration_type', dataIndex: 'integration_type', isSorting: true},
  {title: 'Integration Name', key: 'integration_name', dataIndex: 'integration_name', isSorting: true},
  {title: 'Integration Endpoint', key: 'integration_url', dataIndex: 'integration_url', isSorting: true},
  // {title: 'Created On', key: 'created_at', dataIndex: 'created_at', isSorting: true},
  // {title: 'Details', key: 'details', dataIndex: 'details', isSorting: false},
  {title: 'Status', key: 'enabled', dataIndex: 'enabled', isSorting: false},
  {title: 'Actions', key: 'action', dataIndex: 'action', isSorting: false},
]

export interface IAlertMsg {
  show: boolean,
  text: string
  type: AlertMsgType
}

interface IInitialState {
  data: IntegratedDataI[]
  loading: 'idle' | 'pending'
  columns: any[]
  total_count: number
  currentRequestId: string | undefined
  filters: {
    pageid: number
    items: number
    keyword: string
    clients: string[]
    integrations: string[]
    status: 'Enabled' | 'Disabled' | ''
    sorting_type: 'asc' | 'desc' | ''
    sorting_col: string
  }
}

const initialState: IInitialState = {
  data: [],
  columns: INTERGRATION_TABLE_COLUMNS,
  loading: 'idle',
  total_count: 0,
  currentRequestId: undefined,
  filters: {
    items: 10,
    pageid: 1,
    keyword: '',
    clients: [],
    integrations: [],
    status: '',
    sorting_type: '',
    sorting_col: ''
  }
}

export const fetchIntegrationsByFilters = createAsyncThunk(
  'Integrations/fetchIntegrationsByFilters',
  async (filters: any, {getState, requestId}) => {
    // @ts-ignore
    // const {currentRequestId, loading} = getState()
    // if (loading !== 'pending' || requestId !== currentRequestId) {
    //   return
    // }
    let _params = {...filters}
    _params.clients = joinArrayTostring(_params.clients || [])
    _params.integrations = joinArrayTostring(_params.integrations || [])
    const response = await fetchIntegrationLists({..._params, endpoint: 'connected_integrations'})
    return response.data
  }
)

const IntegrationsReducer = createSlice({
  name: 'Integrations',
  initialState,
  reducers: {
    setData(state, action: PayloadAction<{ data: IntegratedDataI[], total_count: number }>) {
      state.data = action?.payload?.data || []
      state.total_count = action?.payload?.total_count || 0
    },
    setFilters(state, action: PayloadAction<any>) {
      state.filters = {
        ...state.filters,
        ...action.payload
      }
    },
    setInitialFilters(state, action: PayloadAction<null>) {
      state.filters = initialState.filters
    },
    updateStatus(state, action: PayloadAction<string>) {
      const index = state.data.findIndex(o => o.integration_id === action.payload)
      const status = state.data[index].enabled
      if (status === 0) {
        state.data[index].enabled = 1
      } else {
        state.data[index].enabled = 0
      }
    },
  },
  extraReducers: {
    // @ts-ignore
    [fetchIntegrationsByFilters.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    // @ts-ignore
    [fetchIntegrationsByFilters.fulfilled]: (state, action) => {
      const {meta: {requestId}, payload: {data = [], total_count = 0}} = action
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.data = data
        state.total_count = total_count
        state.currentRequestId = undefined
      }
    },
    // @ts-ignore
    [fetchIntegrationsByFilters.rejected]: (state, action) => {
      const {requestId} = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        // state.error = action.error
        state.currentRequestId = undefined
      }
    }
  }
})

export const {
  setData,
  setFilters,
  setInitialFilters,
  updateStatus,
} = IntegrationsReducer.actions

export default IntegrationsReducer.reducer